import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import { graphql } from "gatsby"

import Album from "../../components/album/album"

const Shop = ({ data }) => (
  <Layout>

    <Seo title="Shop" bodyClass='st' />

    <div className="column column--content">

      {/* <Album
        cover={data.lowkey.childImageSharp.gatsbyImageData}
        title="Lowkey"
        description={`Description`}
        // Links
        available="yes"
        stream="yes"
        buy="no"
        // paypal="3U6VE5N8D7552"
        // spotify="7IsviIAputZiRftSPEsekD"
        // applemusic="https://music.apple.com/ca/album/slight-detour-ep/1558556399"
        // itunes="https://music.apple.com/ca/album/slight-detour-ep/1558556399"
        soundcloud="https://www.soundcloud.com/specyal-t-productions/lowkey-specyal-t/ "
      /> */}

      <Album
        cover={data.foresight.childImageSharp.gatsbyImageData}
        title="Foresight"
        description={`Specyal T’s fourth studio album. It’s life after the Remedy, with a new deeper perspective... Foresight.`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal=""
        spotify="4JYsXw2HQwZVoJGow1Awhe"
        applemusic="https://music.apple.com/ca/album/foresight/1700713598"
        itunes="https://music.apple.com/ca/album/foresight/1700713598"
        soundcloud="https://on.soundcloud.com/qGw8J"
      />

      <Album
        cover={data.sdep.childImageSharp.gatsbyImageData}
        title="Slight Detour EP"
        description={`Take a Slight Detour and escape your everyday. Teleport your mind to a place of relaxation and chill vibes with the smooth, soulful sounds of Specyal T’s 5th Studio EP.`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="3U6VE5N8D7552"
        spotify="7IsviIAputZiRftSPEsekD"
        applemusic="https://music.apple.com/ca/album/slight-detour-ep/1558556399"
        itunes="https://music.apple.com/ca/album/slight-detour-ep/1558556399"
        soundcloud="https://soundcloud.com/specyal-t-productions/sets/specyal-t-slight-detour-ep"
      />

      <Album
        cover={data.asr.childImageSharp.gatsbyImageData}
        title="A Specyal Remedy"
        description={`A sophisticated blend of love, heartbreak, and home truths for the grown and sexy. Musical soul food that’s sure to leave you craving more. It’s the final Dose in her Dose Of Distinction Trilogy...it's A Specyal Remedy! Which all began ten years ago!`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="L3XX9N7YEBVRE"
        spotify="4IiST8jRbgoHIt5KhdDRa5"
        applemusic="https://music.apple.com/ca/album/a-specyal-remedy/1508086270"
        itunes="https://music.apple.com/ca/album/a-specyal-remedy/1508086270"
        soundcloud="https://soundcloud.com/specyal-t-productions/sets/specyal-t-a-specyal-remedy-lp"
      />

      <Album
        cover={data.girlfriday.childImageSharp.gatsbyImageData}
        title="Girl Friday EP"
        description={`This surprise album come together from conception to completion in a month's time. After T came across a talented producer from Cali, she became inspired by his musical vibe and "Girl Friday" was born!`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="6KLK5PVVEHJZN"
        spotify="5m7ALDerwo47K0R6uS3YNK"
        applemusic="https://itunes.apple.com/ca/album/girl-friday-ep/1448595914"
        itunes="https://itunes.apple.com/ca/album/girl-friday-ep/1448595914"
        soundcloud="https://soundcloud.com/specyal-t-productions/sets/girl-friday-ep-specyal-t"
      />

      <Album
        cover={data.tionne.childImageSharp.gatsbyImageData}
        title="Tionne EP"
        description={`This album conveys the journey, from a first hand account, of my second pregnancy. The daily struggle of suffering from the debilitating effects of the potentially life-threatening disease known as Hyperemesis Gravidarum. The fear of not knowing if my unborn child would make it to term, and trying to find the strength every day to push forward. The shock of her sudden early arrival, her untimely passing, and life now without her! I personally am a 2x survivor, sadly my second child passed away recently in part due to it! Please help support the movement by spreading awareness and donating today! HER Foundation <a href="www.hyperemesis.org">www.hyperemesis.org</a>. Thx in advance :)`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="XKM8CZN8R7JKY"
        spotify="0PWMLSw7o4Kco9g678s6jj"
        applemusic="https://music.apple.com/ca/album/tionne-ep/1296415024"
        itunes="https://geo.itunes.apple.com/ca/album/tionne-ep/1296415024?app=itunes&at=1001lvgZ"
        soundcloud="https://soundcloud.com/specyal-t-productions/sets/specyal-t-tionne-ep"
      />

      <Album
        cover={data.cake.childImageSharp.gatsbyImageData}
        title="C.A.K.E EP"
        description={`Specyal T’s Second Studio EP. She's Creative, she's Ambitious, she's Kissable and she's always Entertaining! Includes her singles "Stay With Me" & "Just A Thot." There's always room for C.A.K.E.`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="XKUDC98DF3LYY"
        spotify="5Ipt4wKUR80O6FGVBADwX7"
        applemusic="https://music.apple.com/ca/album/c-a-k-e-ep/1043251013"
        itunes="https://geo.itunes.apple.com/ca/album/c-a-k-e-ep/1043251013?app=itunes&at=1001lvgZ"
        soundcloud="https://soundcloud.com/specyal-t-productions/sets/cake-ep-by-specyal-t"
      />

      <Album
        cover={data.dose2.childImageSharp.gatsbyImageData}
        title="Dose Of Distinction 2"
        description={`Specyal T's much anticipated sophomore album has arrived! It's the follow up to her debut Dose Of Distinction and Includes her single &quot;Almost Lover&quot;. Beautifully haunting melodies with deep lyrical story telling, to catchy up tempo rhythms with clever one liners. Your ears won't be disappointed! Enjoy your 2nd Dose!`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="824Q25RC93FK2"
        spotify="1utHhK0A9usruJTNZgO8cV"
        applemusic="https://music.apple.com/ca/album/dose-of-distinction-2/1076975554"
        itunes="https://geo.itunes.apple.com/ca/album/dose-of-distinction-2/1076975554?app=itunes&at=1001lvgZ"
        soundcloud=""
      />

      <Album
        cover={data.dose.childImageSharp.gatsbyImageData}
        title="Dose Of Distinction"
        description={`Specyal T's debut album is finally here! Includes her singles &quot;Ice Cream Cone&quot;, &quot;Sweat It Up!&quot;, &quot;Get Me Thru&quot; &amp; &quot;Airtight&quot; that's gained the industry's attention!<br/>
        Her style is so versatile there's something for everyone from club bangers to moving story telling.<br />
        Here's your first Dose enjoy!`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="T4E7PPQ2YGTDE"
        spotify="3p90p3tRgks0hMj263QIrD"
        applemusic="https://music.apple.com/ca/album/dose-of-distinction/1076970801"
        itunes="https://geo.itunes.apple.com/ca/album/dose-of-distinction/1076970801?app=itunes&at=1001lvgZ"
        soundcloud="https://soundcloud.com/specyal-t-productions/sets/dose-of-distinction-album"
      />

      <Album
        cover={data.sweatitup.childImageSharp.gatsbyImageData}
        title="Sweat It Up! EP"
        description={`Specyal T’s Debut Studio EP featuring her second single and three remixes.`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="A3K97R2MX28XQ"
        spotify="65YhHu86YwJ6RcdiM97ocU"
        applemusic="https://music.apple.com/ca/album/sweat-it-up-ep/385922533"
        itunes="https://geo.itunes.apple.com/ca/album/sweat-it-up-ep/385922533?app=itunes&at=1001lvgZ"
        soundcloud=""
      />
      <Album
        cover={data.ontario.childImageSharp.gatsbyImageData}
        title="Ontario In Your Stereo EP"
        description={`Specyal T's Debut Mixtape EP which features her single "Ice Cream Cone”`}
        // Links
        available="yes"
        stream="yes"
        buy="yes"
        paypal="Y5W8X4JNPPBLU"
        spotify="6h61b9xSv4usVofig6HasA"
        applemusic="https://music.apple.com/ca/album/ice-cream-cone-radio-edit-single/389277698"
        itunes="https://soundcloud.com/specyal-t-productions/ice-cream-cone-specyal-t"
        soundcloud="https://soundcloud.com/specyal-t-productions/sweat-it-up-urban-fusion-remix"
      />

    </div>

  </Layout>
)

export default Shop


export const query = graphql`
query {
  foresight: file(relativePath: {eq: "st-store/albums/foresight.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  lowkey: file(relativePath: {eq: "st-store/albums/lowkey.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  sdep: file(relativePath: {eq: "st-store/albums/slight-detour-ep.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  asr: file(relativePath: {eq: "st-store/albums/a_sweet_remedy.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  girlfriday: file(relativePath: {eq: "st-store/albums/girlfriday-ep.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  tionne: file(relativePath: {eq: "st-store/albums/tionne-ep.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  cake: file(relativePath: {eq: "st-store/albums/cake.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  dose2: file(relativePath: {eq: "st-store/albums/doseofdistinction2.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  dose: file(relativePath: {eq: "st-store/albums/doseofdistinction.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  sweatitup: file(relativePath: {eq: "st-store/albums/sweatitupcover.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
    }
  }
  ontario: file(relativePath: {eq: "st-store/albums/epcover.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`